/*global jQuery */
(function($) {

  'use strict';

  $('.page').find('img').each(function() {
    $(this).addClass('img-responsive');
  });

}(jQuery));
